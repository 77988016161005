(function ($) {

  // Ready
  $(document).ready(function () {

    // galerie
    $('.blocks-gallery-item').click(function () {

      var galleryImages = $(this).parent().find('a');
      var gallery = [];

      galleryImages.each(function (index, galleryItem) {

        var caption = $(this).parent().find('figcaption') ? $(this).find('img').attr('alt') : $(this).parent().find('figcaption');

        gallery.push({
          src: galleryItem.href,
          opts: {
            caption: caption
          }
        })
      });

      $.fancybox.open(gallery, {
        loop: false
      }, $(this).index());

      return false;
    });

    $('.datepicker').pickadate({
      format: 'yyyy-mm-dd',
      today: "Aujourd'hui",
      clear: 'Clear',
      close: 'Close',
      container: '#banniere',
    })

  });

  // Load
  $(window).on('load', function () {
    var vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', vh + 'px');



    var swiperCat = new Swiper('.home-banner', {
      speed: 400,
      effect: 'fade',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      slidesPerView: 1,
    });

    var swiperAvis = new Swiper('.swiper-avis', {
      speed: 400,
      loop: true,
      autoplay: true,
      slidesPerView: 1,
    });

    var swiperPart = new Swiper('.swiper-partenaires', {
      speed: 400,
      loop: true,
      spaceBetween: 50,
      autoplay: true,
      slidesPerView: 4,
    });

    var swiperAct = new Swiper('.swiper-activities', {
      // Optional parameters
      loop: false,
      autoplay: false,
      noSwipingClass: 'noswipe',
      spaceBetween: 0,
      speed: 400,
      slidesPerView: 1,
      effect: 'fade',
      initialSlide: 0
    });
    $('.cta_activite').click(swiperAct, function () {
      swiperAct.slideTo($(this).data("id"));
    });

  });

  // Scroll
  $(window).scroll(function () {

  });


})(jQuery);
