jQuery.fn.extend({

    /* Derniers mots en span */
    lastWord: function () {
        var text = this.text().trim().split(" ");
        var last = text.pop();
        this.html(text.join(" ") + (text.length > 0 ? " <span class='lastWord'>" + last + "</span>" : last));
    },

    /* Compteurs */
    counternumber: function () {
        this.each(function () {
            var $this = $(this),
                countTo = $this.attr('data-num');
            delayTo = $this.attr('delay');
            if (!delayTo) {
                delayTo = 4000
            }
            $({
                countNum: $this.text()
            }).animate({
                countNum: countTo
            }, {
                duration: delayTo,
                easing: 'linear',
                step: function () {
                    $this.text(Math.floor(this.countNum));
                },
                complete: function () {
                    $this.text(this.countNum);
                }
            });
        });
    }
});
